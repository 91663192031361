import React from 'react';
import { Box, Heading, Text, OrderedList, ListItem, Button, Link, UnorderedList} from '@chakra-ui/react';
import ApplicationFAQ from './ApplicationFAQ';
import './fonts/fonts.css';

const ApplicationInfo = () => {
    return (
        <Box
        bg="#FFFFEA"
        borderRadius={{ base: "10px", lg: "20px" }}
        width={{ base: "90%", lg: "94%", "2xl": "90%" }}
        height={{ base: "40%", lg: "80%", "2xl": "90%" }}
        overflow="hidden"
        position="relative"
        py="3%" // Consolidated padding-top and padding-bottom
        >
            <Box p={"5%"}>
                <Heading
                fontSize={{ base: "4vw", lg: "4vw", "2xl": "4vw" }}
                color="black"
                opacity="90%"
                fontFamily="Nohemi"
                align={"center"}
                >
                    2025 CYLC Application Process
                </Heading>
                <Text
                align={"center"}
                fontSize={{ base: "2.3vw", lg: "1.5vw", "2xl": "1vw" }}
                pb={"3%"}
                fontFamily={"NohemiLight"}
                >
                    Welcome to 2025 CYLC; please be aware that the application has a two-step process. You may always 
                    refer to the Application Q&A for additional information.
                </Text>
                <Heading
                fontSize={{ base: "2.3vw", lg: "2vw", "2xl": "2vw" }}
                fontFamily={"Nohemi"}
                >
                    Important Dates and Deadlines
                </Heading>
                <UnorderedList as="ul" fontSize={{base: "2vw", lg:"1.5vw", "2xl": "1vw"}} p={"2%"} fontFamily={"NohemiLight"}>
                    <ListItem>
                        Parent FAQ Meeting: January 11, 2025, 2:00PM CT
                    </ListItem>
                    <ListItem>
                        Applications Begin: January 13, 2025, 8:00PM CT
                    </ListItem>
                    <ListItem>
                        Applications End: Still accepting Step 1 applications
                    </ListItem>
                    <ListItem>
                        Step 2: Acceptance Package Deadline: February 3, 2025
                    </ListItem>
                    <ListItem>
                        C/AC Application Deadline (Resume, Essay): February 14, 2025
                    </ListItem>
                    <ListItem>
                        C/AC Interviews: March 1-2, 2025
                    </ListItem>
                    <ListItem>
                        Notification of C/AC Acceptance: March 1-2, 2025
                    </ListItem>
                    <ListItem>
                        T/TA Application Deadline: March 15, 2025
                    </ListItem>
                    <ListItem>
                        C/AC Meet and Greet: March 29, 2025
                    </ListItem>
                    <ListItem>
                        C/AC Trainings:
                        <OrderedList as="ol" fontSize={{base: "2vw", lg:"1.5vw", "2xl": "1vw"}} p={"2%"} styleType={"lower-alpha"}>
                            <ListItem>
                                June 21, 2025 - June 22, 2025
                            </ListItem>
                            <ListItem>
                                June 28, 2025 - June 29, 2025
                            </ListItem>
                            <ListItem>
                                July 12, 2025
                            </ListItem>
                            <ListItem>
                                Move-In Day: July 13, 2025
                            </ListItem>
                        </OrderedList>
                    </ListItem>
                    <ListItem>
                        CYLC 2025: July 14, 2025 - July 19, 2025
                    </ListItem>
                    <ListItem>
                        Camp Reunion: July 26, 2025 (Tentative, will be confirmed)
                    </ListItem>
                </UnorderedList>
                <Heading
                fontSize={{base: "2.3vw", lg: "2vw", "2xl": "2vw" }}
                mt={"2%"}
                fontFamily={"Nohemi"}
                >
                    CYLC Parents’ conference is a Zoom meeting on Saturday, 1/11/2025 from 2:00-3:00pm CT. We welcome parents and students to join us to better learn about CYLC and the application process. 
                </Heading>
                <Text
                fontSize={{ base: "2.3vw", lg: "1.8vw", "2xl": "1.4vw" }}
                p={"2%"}
                color={"#00cecb"}
                fontFamily={"NohemiLight"}
                >
                    Link: <Link href="https://us06web.zoom.us/j/89878633171?pwd=kHzinDb8xUaL1N0KL8Kw1n8kaXHAkl.1" isExternal>https://us06web.zoom.us/j/89878633171?pwd=kHzinDb8xUaL1N0KL8Kw1n8kaXHAkl.1</Link> <br/>
                    Meeting ID: 898 7863 3171 <br/>
                    Passcode: 355452
                </Text>
                <Heading
                fontSize={{ base: "2.3vw", lg: "2vw", "2xl": "2vw" }}
                fontFamily={"Nohemi"}
                >
                    Step One: Application (Opens Monday, 1/13/2025)
                </Heading>
                <OrderedList as="ol" fontSize={{base: "2vw", lg:"1.5vw", "2xl": "1vw"}} p={"2%"} fontFamily={"NohemiLight"}>
                    <ListItem>
                        Fill out the Step 1 Google Form, linked on the Application tab of the website. Please use the parent’s email address for the primary email. 
                    </ListItem>
                    <ListItem>
                        After you submit the Step 1 Google Form, a copy of your responses will be sent to your email. Please save this for your own records.
                    </ListItem>
                    <ListItem>
                        CYLC (cylcdfw@gmail.com) will send an Acceptance Email if the camper is accepted, depending on the availability of camper spots for the applicant’s grade level. Camper slots are available on a first-come, first-serve basis. This Acceptance Email will be sent within 7 days. 
                    </ListItem>
                    <ListItem>
                        Applicants will be placed on the waiting list if the applicant’s grade level is full and CYLC will notify you as such as well.
                    </ListItem>
                </OrderedList>
                <Box display="flex" justifyContent={"center"} p={"4%"}>
                    <Button as="a" w={"60%"} h={"5vw"} bg={"#FFED68"} borderRadius={"20px"} fontSize={{base: "2vw", lg:"4w", "2xl": "2vw"}} p={"1%"} fontFamily={"NohemiLight"} href="https://forms.gle/fbf1A6rKgMReSFL49" target="_blank">
                        Link to Application
                    </Button>
                </Box>
                <Heading
                fontSize={{ base: "2.3vw", lg: "2vw", "2xl": "2vw" }}
                fontFamily={"Nohemi"}
                >
                    Step Two: Acceptance Package (After Receiving an Acceptance Email)
                </Heading>
                <OrderedList as="ol" fontSize={{base: "2vw", lg:"1.5vw", "2xl": "1vw"}} p={"2%"} fontFamily={"NohemiLight"}>
                    <ListItem>
                        Once you receive the Acceptance Email from CYLC (this is not just a copy of your Google Form responses), please complete the Step 2 Acceptance Package Google Form found in the Acceptance Email. 
                    </ListItem>
                    <ListItem>
                        Fill out the request information accurately. Print/complete/scan the waiver forms back into the Google Form as needed.
                    </ListItem>
                    <ListItem>
                        A complete Acceptance Package should include:
                        <OrderedList as="ol" fontSize={{base: "2vw", lg:"1.5vw", "2xl": "1vw"}} p={"2%"} styleType={"lower-alpha"}>
                            <ListItem>
                                Google Form responses for camper and parent information
                            </ListItem>
                            <ListItem>
                                Copy of the camper’s Health Insurance
                            </ListItem>
                            <ListItem>
                                A&M Commerce Agreement for Waiver, Indemnification, Assumption of Risk and Medical Treatment 
                                Authorization Scan
                            </ListItem>
                            <ListItem>
                                A&M Commerce MRC Recreation Form Waiver
                            </ListItem>
                            <ListItem>
                                A&M Commerce Medical Release Form
                            </ListItem>
                            <ListItem>
                                CYLC Medical Release Form/Activity Permission Slip Waiver Scan
                            </ListItem>
                            <ListItem>
                                Camp fee check $730 (check payable to CYLC and mailed via physical mail. This will be the only thing that is mailed)
                            </ListItem>
                            <ListItem>
                                Mail the check to “CYLC, P.O. Box 868156, Plano, TX 75086.”
                            </ListItem>
                        </OrderedList>
                    </ListItem>
                    <ListItem>
                        Mail the check to “CYLC, P.O. Box 868156, Plano, TX 75086.”
                    </ListItem>
                    <ListItem>
                        The complete Acceptance Package must be postmarked by Monday, 2/3/25.  No late or incomplete Acceptance Packages will be accepted.  If you are on the waiting list, you will be notified of the status of your application sometime after Monday, 2/3/25.
                    </ListItem>
                </OrderedList>
                <Text
                fontSize={{ base: "2.3vw", lg: "1.5vw", "2xl": "1.3vw" }}
                style={{color: "#FF716E"}}
                fontFamily={"Nohemi"}
                >
                    Note: DO NOT mail in an Acceptance Package without receiving the Acceptance Email from CYLC.  Failure to follow this instruction may result in disqualification of the application altogether. The Application Q&A below is available for additional information.
                </Text>
                <ApplicationFAQ />
                <Text
                fontSize={{ base: "2.3vw", lg: "1.5vw", "2xl": "1.3vw" }}
                style={{color: "#FF716E"}}
                fontFamily={"Nohemi"}
                >
                    NOTE: The dates and training location may be changed subject to availability - CYLC will notify C/ACs by email in the event of any changes. Each C/AC team will need to do some additional work outside of the three training weekends. It is very important for C/ACs to commit to the training schedule so that we can be prepared for camp.
                </Text>
                <Heading
                fontSize={{ base: "2.3vw", lg: "2vw", "2xl": "2vw" }}
                mt={"2%"}
                fontFamily={"Nohemi"}
                >
                    *For any other questions, please email cylcdfw@gmail.com
                </Heading>
            </Box>
        </Box>
    );
};

export default ApplicationInfo;